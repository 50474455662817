/** @jsx jsx */
import { jsx } from "theme-ui";
import { Styled } from "theme-ui";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";

const PostTemplate = ({ data, location, pageContext }) => {
  const post = data.mdx;
  const featuredImage = post.frontmatter.featuredImage || null;
  const featuredImageAlt = post.frontmatter.featuredImageAlt || null;

  // const { previous, next } = pageContext;

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
        image={featuredImage || null}
        location={location}
      />
      <article>
        <header>
          <Styled.h1>{post.frontmatter.title}</Styled.h1>
          <Styled.p
            sx={{
              display: `block`,
            }}
          >
            {post.frontmatter.date}
          </Styled.p>
          {featuredImage && (
            <GatsbyImage
              image={featuredImage.childImageSharp.gatsbyImageData}
              alt={featuredImageAlt}
            />
          )}
        </header>
        <div
          sx={{
            display: `flex`,
            flexFlow: `row-reverse nowrap`,
            margin: `0 auto`,
            justifyContent: `center`,
            padding: `2rem`,
            border: "1px solid rgba(52, 61, 68, 0.05)",
            backgroundColor: `white`,
          }}
        >
          {/* <nav
            sx={{
              width: `500px`,
              paddingLeft: `40px`,
              marginTop: `2rem`,
              display: `none`,
              "@media screen and (min-width: 1089px)": {
                display: `block`,
              },
            }}
          >
            {post?.tableOfContents?.items && (
              <TableOfContents items={post.tableOfContents.items} />
            )}
          </nav> */}
          <div
            sx={{
              width: `100%`,
              maxWidth: `60ch`,
            }}
          >
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
      </article>

      {/* <Pagination
        previous={
          previous
            ? { link: previous.fields.slug, title: previous.frontmatter.title }
            : false
        }
        next={
          next
            ? { link: next.fields.slug, title: next.frontmatter.title }
            : false
        }
      /> */}
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
        featuredImageAlt
      }
      tableOfContents
      body
    }
  }
`;
